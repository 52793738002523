import { weightOptions } from 'constants/weightOptions';

import shoppinglist from 'api/definitions/shoppinglist';

import { SHOPPING_LIST_SCHEMA } from 'redux/schemas';

import { getProductByLineNumber } from 'redux/modules/entities/selectors/products';
import { getShoppingListHasLineNumber } from 'redux/modules/entities/selectors/shopping-lists';
import {
  SHOPPING_LIST_FULFILLED,
  SHOPPING_LIST_TOGGLE_MEMBERSHIP,
  SHOPPING_LIST_TOGGLE_MEMBERSHIP_MULTIPLE,
} from 'redux/modules/shopping-list/actions/types';

export const toggleListMembership =
  ({ lineNumber, shoppingListId }) =>
  async (dispatch, getState) => {
    const state = getState();

    let lineNumbersArr = [];
    if (lineNumber.includes(',')) {
      lineNumbersArr = lineNumber.split(',');
    }

    let isMember = false;
    let toggleItem = [];

    let numOfItemsInList = 0;

    if (lineNumbersArr.length > 0) {
      lineNumbersArr.forEach(lineNumElement => {
        if (getShoppingListHasLineNumber(state, shoppingListId, lineNumElement)) {
          isMember = true;
          // eslint-disable-next-line no-plusplus
          numOfItemsInList++;
        }
        const { defaultQuantity } = getProductByLineNumber(state, lineNumElement);
        toggleItem = [
          ...toggleItem,
          {
            quantity: {
              amount: weightOptions[defaultQuantity.uom].default,
              uom: defaultQuantity.uom,
            },
            lineNumber: lineNumElement,
          },
        ];
      });
      if (numOfItemsInList !== lineNumbersArr.length) {
        isMember = false;
        numOfItemsInList = 0;
      }
    } else {
      isMember = getShoppingListHasLineNumber(state, shoppingListId, lineNumber);
      const { defaultQuantity } =
        lineNumbersArr.length === 0 ? getProductByLineNumber(state, lineNumber) : {};
      toggleItem = {
        quantity: {
          amount: weightOptions[defaultQuantity.uom].default,
          uom: defaultQuantity.uom,
        },
        lineNumber,
      };
    }

    const toggleLocalMembership = () => {
      if (lineNumbersArr.length > 0) {
        dispatch({
          type: SHOPPING_LIST_TOGGLE_MEMBERSHIP_MULTIPLE,
          payload: {
            lineNumber: toggleItem,
            shoppingListId,
          },
        });
      } else {
        dispatch({
          type: SHOPPING_LIST_TOGGLE_MEMBERSHIP,
          payload: {
            ...toggleItem,
            shoppingListId,
          },
        });
      }
    };

    const fetchAndUpdateMembership = async join => {
      // to avoid losing items added in another tab we need to fetch the latest list,
      // update it to add or remove this item and put the list back immediately.
      //
      // NOTE: this multi-step API interaction would be more performant as a GraphQL mutation

      const apiArgs = { dispatch, getState };
      const fetchShoppingList = shoppinglist.get({ shoppingListId });
      const latestList = await fetchShoppingList(apiArgs);

      // add or remove item
      const otherItems = latestList.items.filter(item => item.lineNumber !== lineNumber);

      const nextItems = join ? [...otherItems, toggleItem] : otherItems;

      // update list
      const updateShoppingList = shoppinglist.put({
        shoppingListId,
        body: { ...latestList, items: nextItems },
      });

      return updateShoppingList(apiArgs);
    };

    const fetchAndUpdateMembershipMultiple = async join => {
      const apiArgs = { dispatch, getState };
      const fetchShoppingList = shoppinglist.get({ shoppingListId });
      const latestList = await fetchShoppingList(apiArgs);

      // add or remove item
      const otherItems = latestList.items.filter(
        item => !toggleItem.some(t => t.lineNumber === item.lineNumber),
      );

      const nextItems = join ? [...otherItems, ...toggleItem] : otherItems;
      // update list
      const updateShoppingList = shoppinglist.put({
        shoppingListId,
        body: { ...latestList, items: nextItems },
      });

      return updateShoppingList(apiArgs);
    };

    // optimistically update local state to indicate that item is toggled
    // and avoid the perceived slowness of fetching whole list, modfying it and
    // putting it back again via API
    toggleLocalMembership();

    try {
      // pure API fetch, update and put, not affecting local state
      let updatedList;

      if (lineNumbersArr.length > 0) {
        updatedList = await fetchAndUpdateMembershipMultiple(!isMember);
      } else {
        updatedList = await fetchAndUpdateMembership(!isMember);
      }

      // update internal store with updated list
      dispatch({
        type: SHOPPING_LIST_FULFILLED,
        result: updatedList,
        schema: SHOPPING_LIST_SCHEMA,
      });
    } catch (e) {
      // something went wrong with API, revert the local state
      toggleLocalMembership();
    }
  };
