import { connect } from 'react-redux';

import { getProductReviews } from 'redux/modules/product-details/selectors/product-properties';

import StarRating from './StarRating';

export default connect((state, { productId }) => {
  const { averageRating, reviewCount } = getProductReviews(state, productId) || {};

  return { averageRating, reviewCount };
})(StarRating);
