import React from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.scss';

const Icon = props => {
  const { name } = props;
  return styles[name] ? <span className={styles[name]} /> : null;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
