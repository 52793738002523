import React from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';

import styles from 'components/ProductDetails/WriteReview/WriteReview.scss';

const submitReview = id => id && root.$BV && root.$BV.ui('rr', 'submit_review', { productId: id });

const WriteReview = ({ children, lineNumber }) => (
  <button
    className={styles.writeReview}
    // eslint-disable-next-line react/no-unknown-property
    displayAsLink
    onClick={event => submitReview(lineNumber) && event.preventDefault()}
    type="button"
  >
    {children}
  </button>
);

WriteReview.propTypes = {
  children: PropTypes.node.isRequired,
  lineNumber: PropTypes.string,
};

WriteReview.defaultProps = { lineNumber: '' };

export default WriteReview;
