import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import Icon from 'components/wdx/Iconography/old/cssIcons/Icon';
import { PRODUCT_ATTRIBUTES } from 'constants/productAttributes';

import styles from 'components/Product/Attributes/ProductAttributes.scss';

const LEAD_TIME = 'leadTime';

const formatAttribute = ({ label, type } = {}, shortLeadTime) => {
  switch (type) {
    case LEAD_TIME:
      if (shortLeadTime) return `${label} notice`;
      return [
        'Requires ',
        <span className={styles.bold} key={`${LEAD_TIME}-label`}>
          {label}
        </span>,
        ' notice',
      ];
    default:
      return label;
  }
};

const ProductAttributes = ({ attributes, boxed, display, identifier, noIcons, shortLeadTime }) => {
  if (!attributes || (attributes && Object.keys(attributes).length < 1)) return null;

  const mappedAttributes = PRODUCT_ATTRIBUTES.filter(({ type }) => attributes[type]);

  Object.keys(attributes).forEach(type => {
    const label = attributes[type];

    if (typeof label === 'string') {
      mappedAttributes.push({ label, type });
    }
  });

  return (
    <ul
      aria-label="product attributes"
      className={classNames(styles.productAttributes, {
        [styles.grid]: display === 'grid',
      })}
      data-test={identifier}
      role="group"
    >
      {mappedAttributes.map(attribute => {
        const { label, type } = attribute;

        return (
          <li
            className={classNames(styles.attribute, {
              'col-sm-1of2 col-lg-1of3 col-xl-1of4': display === 'grid',
              [styles.boxed]: boxed,
            })}
            data-type={type}
            role="presentation"
            key={`${type}-${label}`}
          >
            {!noIcons && (
              <span aria-hidden="true" className={styles.icon}>
                <Icon name={type} />
              </span>
            )}
            {formatAttribute(attribute, shortLeadTime)}
          </li>
        );
      })}
    </ul>
  );
};

ProductAttributes.propTypes = {
  attributes: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  boxed: PropTypes.bool,
  display: PropTypes.oneOf(['grid', 'inline']),
  identifier: PropTypes.string,
  noIcons: PropTypes.bool,
  shortLeadTime: PropTypes.bool,
};

ProductAttributes.defaultProps = {
  attributes: {},
  boxed: false,
  display: 'inline',
  identifier: undefined,
  noIcons: false,
  shortLeadTime: false,
};

export default ProductAttributes;
