import React, { memo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Badge } from '@johnlewispartnership/wtr-ingredients/ingredients/Badge';
import { mapDataToBadges } from 'components/ProductPod2/utils';

// TODO: [SSR][WARN] Does this impact SSR?
// TODO: migrate to useClientSideMediaQuery or SSRMediaQuery
import { useMediaQuery } from 'react-responsive';
import styles from './ProductBadge.scss';

const MAX_WIDTH_FOR_CORONATION = 342;

export type ProductBadgeProps = {
  text?: string;
  variant?: string;
  className?: string;
  hideInMobile?: boolean;
};

const ProductBadge = ({
  text,
  variant = 'marketing',
  className,
  hideInMobile,
}: ProductBadgeProps) => {
  const [isXS, setIsXS] = useState(false);
  const isXSBreakpoint = useMediaQuery({ maxWidth: MAX_WIDTH_FOR_CORONATION });

  useEffect(() => {
    setIsXS(isXSBreakpoint);
  }, [isXSBreakpoint]);

  if (variant === 'entertaining') {
    return text ? (
      <div
        className={classNames(styles.badge, styles['variant-entertaining'], {
          [className as string]: !!className,
          [styles.hideInMobile]: hideInMobile,
        })}
        data-testid="product-badge"
      >
        {text}
      </div>
    ) : null;
  }

  let coronationShort;
  if (isXS && text === 'Coronation edition') coronationShort = 'Coronation';

  return text ? (
    <Badge
      className={classNames(styles.badge, {
        [className as string]: !!className,
        [styles.hideInMobile]: hideInMobile,
      })}
      data-testid="product-badge"
      text={coronationShort || text}
      type={mapDataToBadges({ marketingBadges: [{ name: text }] })[0]?.type || 'default'}
    />
  ) : null;
};

export default memo(ProductBadge);
